/**
 * api接口统一管理
 */
import { get, post, jsonp } from './http'
import events from '@/bus/bus'

/**
 * 获取字体列表
 */
export const getFontList = p => get('/api/v2/font/getfontlist', p)

/**
 * 获取ai资产数
 * @param {*} p
 * @returns
 */
export const getUserAiTimes = p => get('/api/v1/photosir/assets/get?funcname=AITIMES', p)

/**
 * 记录登录日志
 * @param {Object} p 请求参数
 */
export const saveVisitLog = p => jsonp('/api/v1/log/visit', p)

/**
 * 账号登录
 * @param {*} p
 * @returns
 */
export const accountLogin = p => post('/api/v1/pc/pclogin', p)

/**
 * 验证码登录
 * @param {*} p
 * @returns
 */
export const checkcodeLogin = p => post('/api/v1/pc/checkcode', p)

/**
 * 获取验证码
 * @param {*} p
 * @returns
 */
export const sendCommonCode = p => post('/api/v1/pc/sendCommonCode', p)

/**
 * 试试手气
 * @param {*} p
 * @returns
 */
export const random = p => get('/api/v2/tool/ai/template/random', p)

/**
 * 生成方式
 * @param {*} p
 * @returns
 */
export const queryparambyname = p => get('/api/v1/common/queryparambyname', p)

/**
 * 更多风格
 * @param {*} p
 * @returns999
 */
export const queryall = p => get('/api/v2/tool/ai/style/queryall', p)

/**
 * H5获取生成图层
 * @param {*} p photoid 0表示第一个 sortrule 1上一张 2下一张(photoid=0时不起作用)
 * @returns999
 */
export const getaigcphoto = p => get('/api/v4/space/getaigcphoto', p)

/**
 * 做同款
 * @param {*} p
 * @returns
 */
export const gcsame = p => jsonp('/api/v4/tool/aigc/sd/gcsame', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 图像扩展
 * @param {*} p
 * @returns
 */
export const inpaintgetimg = p => jsonp('/api/v4/tool/aigc/sd/inpaint', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 局部替换
 * @param {*} p
 * @returns
 */
export const replace = p => jsonp('/api/v4/tool/aigc/sd/replace', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 局部擦除
 * @param {*} p
 * @returns
 */
export const remove = p => jsonp('/api/v4/tool/aigc/sd/remove', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 文生图
 * @param {*} p
 * @returns
 */
export const textgetimg = p => post('/api/v3/tool/aigc/sd/textgetimg', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 图生图
 * @param {*} p
 * @returns
 */
export const imggetimg = p => post('/api/v3/tool/aigc/sd/imggetimg', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 生成景深、色块（语义）、轮廓图
 * @param {*} p
 * @returns
 */
export const typegetimg = p => post('/api/v3/tool/aigc/sd/typegetimg', p)

/**
 * 高清放大
 * @param {*} p
 * @returns
 */
export const extrasforimg = p => post('/api/v3/tool/aigc/sd/extrasforimg', p).finally(function () {
  setTimeout(function () {
    events.emit('refreshAiTimes')
  }, 1000)
})

/**
 * 主体识别
 * @param {*} p
 * @returns
 */
export const subjectIdentification = p => post('/api/v2/tool/imageMatting', p)

/**
 * AI识别
 * @param {*} p
 * @returns
 */
export const segmentDetect = p => post('/api/v3/tool/aigc/sd/segmentdetect', p)

/**
 * 保存当前所有数据到服务器
 * @param {*} p
 * @returns
 */
export const saveToServe = p => post('/api/v3/tool/aigc/sd/uploadgcfile', p)

/**
 * 修改服务器上某图片类型
 * @param {*} p
 * @returns
 */
export const modifyToServe = p => post('/api/v3/tool/aigc/sd/changeimagetype', p)

/**
 * 获取服务器上所有数据
 * @param {*} p
 * @returns
 */
export const getToServe = p => get('/api/v3/tool/aigc/sd/listpageuserhistory', p)

/**
 * 删除服务器上某组数据
 * @param {*} p
 * @returns
 */
export const delToServe = p => post('/api/v3/tool/aigc/sd/deleteaigc', p)

/**
 * 获取画廊列表数据
 * @param {*} p
 * @returns
 */
export const getGalleryList = p => get('/api/v3/tool/aigc/sd/listpagegallery', p)

/**
 * 获取我的画廊列表数据
 * @param {*} p
 * @returns
 */
export const getMyGalleryList = p => get('/api/v3/tool/aigc/sd/listpagemygallery', p)

/**
 * 点赞画廊列表中某图片
 * @param {*} p
 * @returns
 */
export const loveGallery = p => post('/api/v3/tool/aigc/sd/love', p)

/**
 * 取消点赞画廊列表中某图片
 * @param {*} p
 * @returns
 */
export const cancelLoveGallery = p => post('/api/v3/tool/aigc/sd/cancellove', p)

/**
 * 共享画廊列表中某图片
 * @param {*} p
 * @returns
 */
export const shareGallery = p => post('/api/v3/tool/aigc/sd/shared', p)

/**
 * 取消共享画廊列表中某图片
 * @param {*} p
 * @returns
 */
export const cancelShareGallery = p => post('/api/v3/tool/aigc/sd/cancelshared', p)

/**
 * 获取微信客服群二维码接口（GET）
 * @param {Object} p 请求参数
 */
export const getWeChatCustomerQrCodeApi = p => get('/api/v1/common/queryparambyname', p)

/**
 * 获取微信客服群二维码接口（GET）
 * @param {Object} p 请求参数
 */
export const getwxconfig = p => get('/api/v1/common/getwxconfig', p)

/**
 * 控制全局共享(post)s
 * @param {Number} 0 不共享 1共享
 *
 */
export const shareAction = p => post('/api/v3/tool/aigc/sd/isshare', p)

/**
 * 查询分享状态
 *
 */
export const getUsershare = p => get('/api/v3/tool/aigc/sd/usershare')

/**
 * 获取标题接口
 */
export const getTitleApi = p => get('/api/v2/tool/gettitle', p)

/**
 * 获取试试手气或提示词接口
 * @param page
 * @param limit
 * @param topictype
 * @param titleid
 */
export const getTemplate = p => get('/api/v2/tool/gettemplate', p)
// 获取每日签到任务列表
export const getattendanceList = p => get('/api/v1/ai/task/attendancelist', p)

// 签到接口
export const userAttendance = p => post('/api/v1/ai/task/attendance', p)

// 获取新手村任务
export const getTask = p => get('/api/v1/ai/task/list', p)

/**
 * 领取点数接口
 */
export const claim = p => post('/api/v1/ai/task/claim', p)
/**
 * 邀请下载
 * @param {*} p
 * @returns
 */
export const invite = p => post('/api/v1/pc/invit', p)

/**
 * 点赞/取消点赞
 接口路径：/api/v4/space/usergc/love
 传输方式：post
 参数：formdata
 {
   "gcid": 1          // 相片gcid
   "status": 1        // 0 取消 1 点赞
 }
 返回数据：
 {
   "code": 0
 }
 */
// 点赞
export const pressLove = p => jsonp('/api/v4/space/community/love', p)
// 取消点赞
export const cancelpressLove = p => jsonp('/api/v4/space/community/cancellove', p)

/**
 * 点赞/取消收藏
 接口路径：/api/v4/space/usergc/love
 传输方式：post
 参数：formdata
 {
   "gcid": 1          // 相片gcid
   "status": 1        // 0 取消 1 点赞
 }
 返回数据：
 {
   "code": 0
 }
 */
// 收藏 / 取消收藏
export const addCollect = p => jsonp('/api/v4/space/community/collect', p)

export const cancelcollect = p => jsonp('/api/v4/space/community/cancelcollect', p)

// 关注
export const follow = p => jsonp('/api/v1/album/user/follow', p)

// 取消关注
export const cancelfollow = p => jsonp('/api/v1/album/user/cancelfollow', p)

/**
 * 共享
 * @param { gcid } p
 * @returns
 */
export const share = p => jsonp('/api/v4/community/gc/share', p)

/**
 * 取消共享
 * @param { gcid } p
 * @returns
 */
export const cancleshare = p => jsonp('/api/v4/community/gc/cancleshare', p)

/**
 * 发布评论
 * @param { photoid, parentid , content, url, surl } p
 * @returns
 * /api/v4/space/usergc/save
 */
export const postComments = p => jsonp('/api/v4/space/discuss/save', p)

/**
 * 评论列表查询
 * @param { photoid 当前id, parentid 回复的评论id, currentpage 当前页, pagesize 每页条数} p
 * @param { gcid、parentid、currentpage、pagesize} p
 * @returns
 * /api/v4/space/usergc/list
 */
export const getCommentList = p => get('/api/v4/space/discuss/list', p)

/**
 * 删除评论
 * @param { id 0 or 回复的评论id } p
 * @returns
 * /api/v4/space/usergc/del
 */
export const commentDel = p => jsonp('/api/v4/space/discuss/del', p)

/**
 * 评论点赞
 * @param {id 评论id} p
 * @returns
 * /api/v4/space/usergc/discusslove
 */
export const commentLove = p => jsonp('/api/v4/space/discuss/love', p)

/**
 * 评论取消点赞
 * @param {id 评论id} p
 * @returns
 * /api/v4/space/usergc/canceldiscusslove
 */
export const commentCancelLove = p => jsonp('/api/v4/space/discuss/cancellove', p)

/**
 * 上传青牛需要的凭证接口
 * @param {*} p
 * @returns
 */
export const getUploadToken = p => post('/api/v1/album/photo/initiatemultipartupload', p)

/**
*上传青牛需要的凭证接口(新)
* @param {*} p
* @returns
*/
export const getUpToken = p => jsonp('/api/v4/album/photo/getuptoken', p)

/**
* qiniu上传图片校验
* @param {*} p
* @returns
*/
export const checkfileurl = p => jsonp('/api/v4/album/photo/checkfileurl', p)

/**
 * 获取 ai_work_class 表全部数据
 * @param {  } p
 * @returns
 */
export const queryWorkType = p => get('/api/v4/ai/workflow/class/queryall', p)

/**
 * 查询工作流列表（社区页面）
 * @param {  } p
 * @returns
 */
export const listrelasebypage = p => get('/api/v4/ai/workflow/listrelasebypage', p)

/**
 * 发布工作流
 * @param {*} p
 * @returns
 */
export const release = p => post('/api/v4/ai/workflow/release', p)

/**
 * 撤回工作流
 * @param {*} p
 * @returns
 */
export const rollback = p => post('/api/v4/ai/workflow/rollback', p)
/**
 * 删除工作流
 * @param {*} p
 * @returns
 */
export const delWorkflow = p => post('/api/v4/ai/workflow/del', p)

/**
 * 社区显示详情
 * @param {*} p
 * @returns
 */
export const getbygcid = p => get('/api/v4/community/gc/getbygcid', p)

/**
 * 查询工作流列表（我的）
 * @param {  } p
 * @returns
 */
export const listbypage = p => get('/api/v4/ai/workflow/listbypage', p)

/**
 * 上传到云空间
 * @param {} P
 * @returns
 */
export const uploadPosterDesign = P => post('/api/v1/album/photo/savebytype', P)

/**
 * 保存
 * @param {} P
 * @returns
 */
export const preserveSaveApi = P => jsonp('/api/v4/ai/workflow/save', P)

/**
 * 生成图片
 * @param {*} p
 * @returns  返回gcid
 */
export const generateImg = p => jsonp('/api/v4/tool/aigc/sd/gc', p)

/**
 * 轮询接口
 * @param {} p
 * @returns
 */
export const getResultByGcid = p => get('/api/v4/tool/aigc/sd/getbygcid', p)

/**
 * 查询明细
 * @param {} p
 * @returns
 */
export const getOtherResultByGcid = p => get('/api/v4/community/gc/getbygcid', p)

/**
 * 查询工作流参数
 * @returns
 */
export const getWorkById = p => get('/api/v4/ai/workflow/getbyid', p)

/**
 * 查询图片列表
 * @param {*} p
 * @returns
 */
// export const getusergclist = p => get('/api/v4/tool/aigc/sd/getusergclist', p)

/**
 * 删除组
 */
export const deleteGc = p => jsonp('/api/v4/tool/aigc/sd/deletegc', p)
/**
 * 删除单个图片
 * @param {*} p
 * @returns
 */
export const deleteImg = p => jsonp('/api/v4/album/photo/delete', p)

/**
 * 图片精修
 * @param {*} P
 * @returns
 */
export const extrasforImg = P => jsonp('/api/v4/tool/aigc/sd/extrasforimg', P)

/**
 * 提取控制图  “filetype”:     // stroke 线稿 depth 景深  swatches 色块
 * @param {*} p
 * @returns
 */
export const getControlImg = p => jsonp('/api/v4/tool/aigc/sd/typegetimg', p)

/**
 * 选定一张
 * @param {*} p
 * @returns
 */
export const confirmImg = p => jsonp('/api/v4/tool/aigc/sd/confirm', p)

export const uploadFile = p => post('/api/v4/tool/aigc/sd/uploadfile', p)

/**
 * 首页统计数据接口：(“我的” 动态/关注/粉丝/收藏 数量获取)
 */
export const listStatistics = p => get('/api/v1/album/community/liststatistics', p)

/**
 * 云空间文件夹列表接口
 接口路径：api/v4/album/myalbum
 传输方式：get
 参数：json
 {
   "currentpage": 1,   // 页码
   "pagesize": 800,    // 每页条数(最多800条)
   "albumclass": 1     // 相册分类: 1 个人  2  企业
   "fatherid": 0       // 一级文件夹列表 传 0 ; 二级或二级以上文件夹列表 传 相册id
   "albumtype": -1     // 相册分类: 默认值 -1 表示不限制  (0 私有; 1 公开; 2 密码; 3 自定义权限; 5 aiGC动态漫; 6 AIGC生图; 7 AI生成海报; 8 海报; 9 默认相册;）
 }
 返回数据：
 {
   code: 0,
   data: {
     total: 0,         // 总条数
     albumList: []     // 文件夹列表
   }
 }
 */
export const myalbum = p => { return get('/api/v4/album/myalbum', p) }

/**
 * 云空间新增/修改文件夹接口
 接口路径：api/v4/album/add
 传输方式：get
 参数：json
 {
   "title": "",        // 名字
   "albumclass": 1,    // 相册分类: 1 个人  2  企业
   "fatherid": 0       // 新增(一级相册 传 0 ; 二级或二级以上 传 父相册id); 修改(一级相册 传 相册id ; 二级或二级以上 传 父相册id)
   "albumid": 0        // 新增(一级相册 不传该参数; 二级或二级以上 传 相册id); 修改(一级相册 不传该参数; 二级或二级以上 传 相册id)
 }
 返回数据：
 {
   "code": 0
 }
 */
export const addAlbum = p => jsonp('/api/v4/album/add', p)

/**
 * 云空间删除文件夹（到回收站）接口
 接口路径：api/v4/album/del
 传输方式：post
 参数：json
 {
   "albumid": 1       // 相册id
 }
 返回数据：
 {
   "code": 0
 }
 */
export const delAlbum = p => { return jsonp('/api/v4/album/del', p) }

/**
 * 云空间二级（文件/文件夹）列表接口：
 接口路径：api/v4/space/cloudspacelist
 传输方式：get
 参数：formdata
 currentpage          // 当前页码
 pagesize             // 每页条数
 sortname             // 排序方式: 'name'(名称排序)、'size'(大小排序)、'time'(时间排序)、'hot'(最火排序)
 sortrule             // 排序方式: 1 正序 2 倒叙
 search               // 查询条件: 可选 如 albumid 有值，则搜索该相册下的文件；如 albumid 空值，则搜索全部相册下的文件
 albumid              // 相册id
 albumclass           // 相册分类: 1 个人  2  企业
 isdelete             // 删除标志: 0 正常数据 1 删除的数据
 albumtype            // 相册分类: 暂时无用处  默认值 -1 表示不限制  (0 私有; 1 公开; 2 密码; 3 自定义权限; 5 aiGC动态漫; 6 AIGC生图; 7 AI生成海报; 8 海报; 9 默认相册;）
 datatype             // 数据类型: 1 表示 仅查询 jpg bmp 等类型的数据
 返回数据：
 {
   "code": 0,
   "data": {
     "totalCount": 1, // 总条数
     "pageSize": 10,  // 每页条数
     "totalPage": 1,  // 总页数
     "currPage": 1,   // 当前页
     "list": [{
       "albumflag": 2 // （相册/相片）区分标志: 1 相册; 2 相片
     }]
   }
 }
 */
export const cloudSpaceList = p => { return get('/api/v4/space/cloudspacelist', p) }

/**
 * 获取个人二级文件夹下的文件列表接口：
 接口路径：api/v4/tool/aigc/sd/getusergclist
 传输方式：get
 参数：formdata
 currentpage          // 当前页码
 pagesize             // 每页条数
 sortname             // 排序方式: 精选 使用 hot 排序； 创意社区  使用 createtime 或者 love 排序
 followuid            // 他人uid: 0 (默认 不起作用)
 search               // 查询条件: 可选 如 albumid 有值，则搜索该相册下的文件；如 albumid 空值，则搜索全部相册下的文件
 type                 // 相册类型： 6 （默认）
 isshared             // 是否公开： 0  (默认 不起作用)； 1 公开
 workid               // id
 albumid              // 相册id
 outuserid:           // 北航查询学生学号
 返回数据：
 {
   "code": 0,
   "data": {
     "totalCount": 1, // 总条数
     "pageSize": 10,  // 每页条数
     "totalPage": 1,  // 总页数
     "currPage": 1,   // 当前页
     "list": [{
       "albumflag": 2 // （相册/相片）区分标志: 1 相册; 2 相片
     }]
   }
 }
 */
export const getUserFileList = p => { return get('/api/v4/tool/aigc/sd/getusergclist', p) }

/**
 * 获取他人二级文件夹下的文件列表接口：
 接口路径：api/v4/community/gc/getlistbypage
 传输方式：get
 参数：formdata
 currentpage          // 当前页码
 pagesize             // 每页条数
 sortname             // 排序方式: 精选 使用 hot 排序； 创意社区  使用 createtime 或者 love 排序
 followuid            // 他人uid: 0 (默认 不起作用)
 search               // 查询条件: 可选 如 albumid 有值，则搜索该相册下的文件；如 albumid 空值，则搜索全部相册下的文件
 type                 // 相册类型： 6 （默认）
 isshared             // 是否公开： 0  (默认 不起作用)； 1 公开
 workid               // id
 albumid              // 相册id
 返回数据：
 {
   "code": 0,
   "data": {
     "totalCount": 1, // 总条数
     "pageSize": 10,  // 每页条数
     "totalPage": 1,  // 总页数
     "currPage": 1,   // 当前页
     "list": [{
       "albumflag": 2 // （相册/相片）区分标志: 1 相册; 2 相片
     }]
   }
 }
 */
export const getOtherFileList = p => { return get('/api/v4/community/gc/getlistbypage', p) }

/**
 * 相片移动路径接口
 接口路径：/api/v4/tool/aigc/sd/movegc
 传输方式：post
 参数：json
 {
   "gcid": 1          // 相片gcid
   "albumid": 1     // 目标相册id
 }
 返回数据：
 {
   "code": 0
 }
 */
export const moveFile = p => { return jsonp('/api/v4/tool/aigc/sd/movegc', p) }

/**
 * 1. 点赞作品列表（动态漫）：
 api/v4/space/usergc/lovelistpage
 方式：get
 参数：token、albumtype（相片传6，动态漫传5）、currentpage、pagesize
 返回：code、data
 */
export const loveListPage = p => get('/api/v4/space/community/lovelistpage', p)

/**
 * 2.收藏作品列表（动态漫）：
 api/v4/space/usergc/collectlistpage
 方式：get
 参数：token、albumtype（相片传6，动态漫传5）、currentpage、pagesize
 返回：code、data
 */
export const collectListPage = p => get('/api/v4/space/community/collectlistpage', p)

/**
 * 设置相册封面
 */
export const setCover = p => jsonp('/api/v4/tool/aigc/sd/setthumbgc', p) // 设为封面

/**
 * 获取关注/粉丝列表接口：
 api/v1/album/user/followlistpage
 方式：get
 参数：token、uid（如果是查自己的这里不用传，也可传0，查别人的，这里就传对方的uid）、type（1 获取关注列表  2 获取粉丝列表）、nickname（非必传）、currentpage、pagesize
 返回：code，data
 */
export const followListPage = p => get('/api/v1/album/user/followlistpage', p)

/**
 * Authenticationkey 换成token+outuserid
 */
export const getuserinfoForBHU = p => get('/api/v1/cooperation/getuserinfo', p)

/**
 * 腾讯渠道广告点击保存数据接口
 * @param {} p
 * @returns
 */
export const saveAds = p => post('/api/v1/tencent/ads/save', p)

/**
 * 腾讯渠道广告用户行为事件
 * @param {*} p
 * @returns
 */
export const clickAds = p => get('/api/v1/tencent/ads/event', p)

/**
 * 腾讯渠道广告用户行为事件(新)
 * @param {*} jsonp
 * @returns
 */
export const tencentReport = p => jsonp('/api/v1/tencent/ads/report', p)

/**
 * 获取系统通知/消息列表
 * api/v1/user/message/getmessagelist
 * 方式：post（json）
 * 参数：token、type（1系统通知 2 消息）、page、limit、id（查询一条的情况传）、source（传8）
 * return：data
 */
export const getMessageList = p => jsonp('/api/v1/user/message/getmessagelist', p)

/**
 * 系统通知/消息未读消息数量
 * api/v1/user/message/unreadcount
 * 方式：get（json）
 * 参数：token、type（1系统通知 2 消息）、source（传8）
 * return：data
 *
 */
export const unreadCount = p => get('/api/v1/user/message/unreadcount', p)

/**
 * 系统通知/消息查看
 * api/v1/user/message/viewmessage
 * 方式：post（json）
 * 参数：token、id（id 不传（为空） 即全部 已读）、type（1系统通知 2 消息）、source（传8）
 * return：data
 */
export const viewMessage = p => jsonp('/api/v1/user/message/viewmessage', p)

/**
 * 系统通知/消息清空（删除）
 * api/v1/user/message/delete
 * 方式：post（json）
 * 参数：token、id（id 不传（为空） 即全部 删除）、type（1系统通知 2 消息）、source（传8）
 * return：data
 */
export const messageDelete = p => jsonp('/api/v1/user/message/delete', p)

/**
 * 获取模特列表
  type :模特类型  fashion 时装秀 change 换衣
  sex: 性别  F 女 M 男 O 未知
  race:   人种 asia亚洲 europe 欧美 africa非洲 latin拉美
  skincolour:肤色 white 白 yellow 黄 brown 棕 black黑

 * @param {*} p
 * @returns
 */
export const getModelList = p => get('/api/v1/ai/model/listbytype', p)

/**
 * AI模特背景获取接口
 * systype= 0
 * name= AI_MODEL_BACKGROUND
 * @param {} p
 * @returns
 */
export const getBgList = p => get('/api/v1/ai/model/listbackground', p)

/**
 * 获取用户特列表
 * @param {*} p
 * @returns
 */
export const getUserListByType = p => get('/api/v1/ai/model/userlistbytype', p)

/**
 * 模特服装识别
 * @param {} p
 * @returns
 */
export const segmentClothWithOptions = p => post('/api/v2/tool/segmentClothWithOptions', p)

/**
 * 模特服装识别(新版)
 * @param {} p
 * @returns
 */
export const segmentmodel = p => post('/api/v2/tool/segmentmodel', p)

/**
 * 本地模特上传
 * @param {} p
 * @returns
 */
export const uploadModel = p => post('/api/v1/ai/model/upload', p)

/**
 * 删除模特儿
 * @param {} p
 * @returns
 */
export const delModelById = p => jsonp('/api/v4/tool/aigc/sd/deletegc', p)

/**
 * 模特试衣接口
 *  modelid：模特ID
 *   modelurl: 模特的url
 *   garmenturl: 衣服文件
 *   garmentdes: 衣服描述
 *   bodypart：服装部位       upperbody 上衣 ,lower 下半身，dresses 连衣裙 ,scarf 围巾 ,upperlower 上衣+下下衣,fullbody 全身 ,bag 包 ,sunglasses 眼镜 ,shoes 鞋,hat 帽子
 *   changetype:   0  遮罩  1 自动
 *   maskurl: 遮罩文件  自动遮罩不需要传

 * @param {*} p
 * @returns
 */

export const modelTryOn = p => jsonp('/api/v3/tool/aigc/sd/model/change', p)

/**
 * 模特时装秀接口
 *
 * "modelid":0,
 "   modelurl": "",   //模特的图片地址
 "   fileurl":"",   //上传的人物图片
 "   prompt":"",//背景提示词
 "   maskurl":"" //遮罩图
 * @param {*} p
 * @returns
 */
export const modelFashion = p => jsonp('/api/v3/tool/aigc/sd/model/fashion', p)

/**
 * type 11时装秀  12换衣
 *
 * @returns
 */
export const getUserModelgGcList = () => get('/api/v4/tool/ai/model/getusergclist')

/**
 * 模特生成
 * @returns
 */
export const gcModel = p => jsonp('/api/v3/tool/aigc/sd/model/gc', p)

// 改用户名昵称
export const editUserName = p => post('/api/v2/pc/editnickname', p)
// 修改用户头像
export const editavatar = p => post('/api/v2/pc/editavatar', p)

/**
 * 作品举报
 * 请求方式： post （FormData）
 * 入参:
    gcid           // 可为空
    classid        // 可为空
    surl           // 可为空
    ourl           // 可为空
    reasons        // 原因（value）多选用英文逗号，隔开
    description    // 原因描述
    token
 */
export const reportWork = p => jsonp('/api/v2/tool/ai/report/save', p)

/**
 * 生成动漫
 */
export const makingAIAC = data => post('/api/v1/aigc/motioncomics/generate', data)

/**
 * 获取社区数据
 */
export const getCommunityData = data => jsonp('/api/v1/aigc/motioncomics/getlist', data)

/**
 * 视频合并
 */
export const videoMerge = data => jsonp('/api/v99/mp4/merge', data)

/**
 * 闪绘图生图 手工
 * @param {*} data
 *  prompt 描述词
    style 模型  取自  上一个接口 ename 字段
    denoising 变化幅度 1 - 100
    file //以图生图文件
    width  //宽 最大 2000
    height  //高 最大 2000

 * @returns
 */
export const imgGetimgPc = data => post('/api/v3/tool/aigc/sd/imggetimgpc', data)

/**
 * 闪绘图生图 全部
 * @param {*} data
 *  prompt 描述词
    style 模型  取自  上一个接口 ename 字段
    denoising 变化幅度 1 - 100
    file //以图生图文件
    width  //宽 最大 2000
    height  //高 最大 2000

 * @returns
 */
export const imggetimgbyturbo = data => post('/api/v3/tool/aigc/sd/imggetimgbyturbo', data)

/**
 * 普通精修
 * @param {*} data
 * prompt 描述词    // 生成时 缩略图的 描述词
   file //以图生图文件

 */
export const extrasbaseImg = data => post('/api/v3/tool/aigc/sd/extrasbase', data)

/**
 * 超级精修
 * @param {} data
 * @returns
 *file //以图生图文件
  width  //目标宽  最大宽 3000
  height  //目标高 最大高 3000
 */
export const extrasfaceImg = data => post('/api/v3/tool/aigc/sd/extrasface', data)

/**
 * 闪绘百度生成
 *
 *  prompt 描述词
    style 模型  取自  上一个接口 ename 字段
    denoising 变化幅度 1 - 100
    file //以图生图文件
    width  //宽 最大 2000
    height  //高 最大 2000
 * @param {} data
 * @returns
 */
export const baiduFlashDrawing = data => post('/baidu/api/v3/ai/imggetimg', data)

/**
 *获取百度的lora
 * @returns
 */
export const baiduGetLoraList = () => get('/baidu/api/v3/ai/stylelist')

/**
 * 百度的精修
 * @returns
 */
export const baiduRefine = (data) => post('/baidu/api/v3/ai/refine', data)
