/**
 * 联想小程序api接口统一管理
 */
import { get, jsonp } from './http'
/**
 * 登录
 * api/v1/pc/loginbylenovo
 * 参数说明
 * account      联想token
 * source       来源固定值 70
 * @param {Object} p 请求参数
 */
export const loginByLenovo = p => jsonp('/api/v1/pc/loginbylenovo', p)

/**
 * 获取 appId 等信息
 * api/v1/pc/getlenovoinfo
 * @param {Object} p 请求参数
 * 参数说明
 * source       来源固定值 70
 */
export const getLenovoInfo = p => get('/api/v1/pc/getlenovoinfo', p)

/**
 * 获取生成的订单信息
 * lenovo/api/v1/pay/trade
 * @param {Object} p 请求参数
 * 参数说明
 * period       卡包标号
 * totalPrice   总价
 * unitPrice    单价
 * number       数量
 * bodyType     购买数据类型
 * ids          选用优惠券id列表字符串（可选）
 * lenovotoken  联想版悟空图像的联想token（可选）
 * source       来源固定值 70
 */
export const getLenovoPayInfo = p => get('/lenovo/api/v1/pay/trade', p)
